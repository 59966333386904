import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Lottie from "react-lottie";
import Header from "../components/Header";
import Layout from "../components/layout";
import ArrowDown from "../lotties/arrow-down";
import styles from "../styles/contact.module.scss";

const downArrow = {
  loop: true,
  autoplay: true,
  animationData: ArrowDown,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Contact = () => {
  // to focus the div element containing the contents
  const ready = () => {
    document.getElementsByClassName(styles.careers)[0].focus();
  };

  useEffect(() => {
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", ready);
    } else {
      ready();
    }
  }, []);

  return (
    <Layout
      title="Contact | Mahusai"
      description="Let's build something brilliant together."
      pathname="/contact/"
    >
      <div
        className={styles.careers}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <Header />

        <Container fluid>
          <Row xs={1} className={styles.row}>
            {/* <Col lg={{ span: 7 }}>
              <h1 className={styles.hello}>Get in touch.</h1>
            </Col> */}
            <Col xs md lg={{ span: 7 }} className="col-height-mobile">
              <div className="details-section center-middle-position">
                <div className="title">Get in touch.</div>
              </div>
            </Col>
            <Col lg={{ span: 5 }} className={styles.bgColumbiaBlue}>
              <div className={`${styles.details} ${styles.centerMiddle}`}>
                <div className={styles.companyName}>MAHUSAI Inc.</div>
                <div className={styles.address}>
                  Level 10-1 One Global Place
                  <br />
                  25th Street &amp; 5th Avenue
                  <br />
                  Bonifacio Global City
                  <br />
                  Taguig City
                  <br />
                  1634 Philippines
                  <br />
                </div>
                <div className={styles.contact}>
                  <span className={styles.label}>PHONE</span> +63232244173
                  <br />
                  <span className={styles.label}>FAX</span> +63232244174
                  <br />
                  <span className={styles.label}>E-MAIL</span>{" "}
                  contact@mahusai.ph
                </div>
              </div>
            </Col>
          </Row>
          <Row xs={1} className={styles.row}>
            <Col lg={{ span: 7 }} className={styles.bgColumbiaBlue}>
              <div className={`${styles.paragraph} ${styles.centerMiddle}`}>
                Let's build something brilliant together. <br />
                <br />
                Think we might be the right friends for your next adventure?
                We'd love to hear from you.
              </div>
            </Col>
            <Col
              lg={{ span: 5 }}
              className={`${styles.bgBdazzledBlue} text-center`}
            >
              <div className={`${styles.details} ${styles.detailsSmallScreen}`}>
                <p className={styles.phrase}>Let's go.</p>
                <Lottie options={downArrow} height="12vh" width="12vh" />
                <a
                  href="mailto:hello@mahusai.ph"
                  className={styles.contactUsLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className={styles.email}>
                    hello@
                    <br />
                    mahus
                    <br />
                    ai.ph
                  </p>
                </a>
                <p className={styles.copy}>COPYRIGHT 2020 • MAHUSAI INC.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default Contact;
